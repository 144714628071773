import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/klc-logo.png';
import { FaBars } from "react-icons/fa";
import './navbar.css';

const Navbar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='navbar'>
        <img src={logo} className='klc-logo' />

        <div className='middle-menu'>
            <p onClick={()=>navigate("/")} >Home</p>

            <p onClick={()=>navigate("/register")} >Get Started</p>

            {/*<p onClick={()=>navigate("/contact")} >Contact Us</p>*/}
        </div>

        <button onClick={() => navigate("/login")} className='login-button'>Login</button>

        <FaBars
          className={`bars-icon ${isMenuOpen ? 'spin' : ''}`} 
          size={34}
          onClick={toggleMenu}
        />

      <div className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
        <p onClick={() => navigate("/")}>Home</p>
        <p onClick={() => navigate("/register")}>Get Started</p>
        {/*<p onClick={() => navigate("/contact")}>Contact Us</p>*/}
        <p onClick={() => navigate("/login")}>Login</p>
        <button className='close-button' onClick={toggleMenu}>Close</button>
      </div>

    </div>
  )
}

export default Navbar