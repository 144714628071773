import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import './privacyPolicy.css';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  
  return (
    <div className='privacy-policy-page'>
      <Navbar />
      <div className='privacy__container'>
          <h3>
          Privacy Policy for Kids Learn Coding LLC
          </h3>

          <h3>
          Last Updated: March 25, 2024
          </h3>

          <div className='toc'>
            <h3>Table of Contents</h3>
            <ol>
                <li><a href="#intro">Introduction</a></li>
                <li><a href="#info">Information Collection and Use</a></li>
                <li><a href="#childrensPolicy">Children’s Privacy</a></li>
                <li><a href="#useOfData">Use of Data</a></li>
                <li><a href="#sharingOfData">Sharing of Data</a></li>
                <li><a href="#securityOfData">Security of Data</a></li>
                <li><a href="#changes">Changes to This Privacy Policy</a></li>
                <li><a href="#yourRights">Your Rights</a></li>
                <li><a href="#contactUs">Contact Us</a></li>
            </ol>
          </div>

          <h1 id="intro" >
          Introduction
          </h1>
          <p>
          Kids Learn Coding LLC, accessible at kidslearncoding.org, is committed to protecting the privacy and security of our users. This Privacy Policy outlines the types of information we collect, how it's used, and the steps we take to protect it.          
          </p>

          <h1 id="info" >
          Information Collection and Use
          </h1>
          <p>
          <strong>Personal Information:</strong> We collect personal information such as name, email address, and age when users register for an account or participate in our services. For users under 13, this information is collected from parents or guardians with their explicit consent.          
          </p>
          <p>
          <strong>Usage Data:</strong> We collect information on how the Services are accessed and used, including details such as IP address, browser type, pages visited, and time spent on pages.
          </p>
          <p>
          <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to track activity on our Service and hold certain information to enhance user experience.
          </p>

          <h1 id="childrensPolicy" >
          Children’s Privacy
          </h1>
          <p>
          Protecting the privacy of young users is especially important to us. We do not collect personal information from anyone under the age of 13 without parental consent. If we become aware that we have inadvertently collected personal information from a child under 13 without consent, we will take steps to remove that information from our servers.
          </p>

          <h1 id="useOfData" >
          Use of Data
          </h1>
          <p>
            <strong>We use the collected data to:</strong>
          </p>
          <ul>
            <li>Provide, maintain, and improve our Service</li>

            <li>Communicate with you about updates or changes to our Service</li>

            <li>Support interactive features and customer support</li>

            <li>Analyze and understand how our Service is used to improve it</li>

            <li>Ensure the security of our Service</li>
          </ul>


          <h1 id="sharingOfData" >
          Sharing of Data
          </h1>
          <p>
          We do not share personal information with third parties except as necessary to provide our services, comply with the law, protect our rights, or ensure the safety of our users.
          </p>

          <h1 id="securityOfData" >
          Security of Data
          </h1>
          <p>
          The security of your data is important to us. We implement appropriate measures to prevent unauthorized access, disclosure, alteration, or destruction of your personal information.
          </p>

          <h1 id="changes" >
          Changes to This Privacy Policy
          </h1>
          <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
          </p>

          <h1 id="yourRights" >
          Your Rights
          </h1>
          <p>
          You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please contact us at the contact information provided below.
          </p>

          <h1 id="contactUs" >
          Contact Us
          </h1>
          <p>
          If you have any questions or suggestions about our Privacy Policy, please contact us by navigating to the "Contact Us" page which can be found by clicking the following link: <span onClick={()=>navigate("/contact")} className='contact-span'>Contact Us</span>
          </p>
          <br/>
          <p>
          By using our Service, you acknowledge that you have read and understood this Privacy Policy.
          </p>
          
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy