// api.js
import axios from 'axios';

// Create an Axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Sets the base URL for all requests
    headers: { 
        'X-App-Auth': process.env.REACT_APP_X_APP_AUTH,
        'Content-Type': 'application/json'
    },
    withCredentials: true // Enables credentials for all requests
});

export default api;
