import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import logo from '../../images/klc-logo.png';
import axios from 'axios';
import moment from 'moment-timezone';
import { DateTime } from 'luxon'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import './register.css';
import Loader from '../../components/loader/loader';
import Pay from './pay/pay';
import api from '../../api/api';
const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE);


const Register = () => {
  const navigate = useNavigate();
  var [currentPage, setCurrentPage] = useState(1);
  var [loading, setLoading] = useState(false);

  // booking data
  var [twizzlerSupreme, setTwizzlerSupreme] = useState({});
  var [selectedDayOfWeek, setSelectedDayOfWeek] = useState("monday");
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [clientSecret, setClientSecret] = useState('');

  // form data
  const [formData, setFormData] = useState({
    parentFirstName: '',
    parentLastName: '',
    phoneNumber: '',
    email: '',
    streetAddress: '',
    city: '',
    stateProvince: '',
    country: '',

    studentFirstName: '',
    studentLastName: '',
    birthday: '',

    password: '',
    confirmPassword: '',
    terms: false,
    priv: false,

    classData: {
        studentTime: '',
        studentTimeZone: '',
        teacherID: '',
        teacherTime: '',
        teacherTimeZone: '',
        studentDOW: '',
        teacherDOW: ''
    }
  });

  // Handle change from inputs
  const { 
    parentFirstName, parentLastName, phoneNumber, email, streetAddress, city, stateProvince, country,
    studentFirstName, studentLastName, birthday,
    password, confirmPassword, terms, priv
  } = formData;

  const handleChange = text => e => {
    setFormData({ ...formData, [text]: e.target.value });
  }

  const handleTermsChange = () => {
    setFormData((prevFormData) => ({ ...prevFormData, terms: !prevFormData.terms }));
  };

  const handlePrivChange = () => {
    setFormData((prevFormData) => ({ ...prevFormData, priv: !prevFormData.priv }));
  };

  


  const goToPageThree = async () => {
    setLoading(true)
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const passwordLetterPattern = /[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z]/;
    const passwordNumberPattern = /[\d].*[\d]/;
  
    // Check if all fields are filled out
    const allFieldsFilled = Object.values(formData).every(value => value !== '');
    if (!allFieldsFilled) {
      toast.error('All fields must be filled out.');
      setLoading(false)
      return;
    }

    const isFormDataComplete = (data) => {
        return Object.values(data).every(value => {
            if (typeof value === 'object' && value !== null) {
            // Recursively check nested objects
            return isFormDataComplete(value);
            }
            return value !== '';
        });
    };

    if (!isFormDataComplete(formData)) {
        toast.error('All fields must be filled out.');
        setLoading(false)
        return;
    }

  
    // Check if email is valid
    if (!emailPattern.test(formData.email.trim())) {
      toast.error('Invalid email format. Email should be like user@example.com');
      setLoading(false)
      return;
    }
  
    // Check if password meets criteria
    if (!passwordLetterPattern.test(formData.password)) {
      toast.error('Password must contain at least six letters.');
      setLoading(false)
      return;
    }
  
    if (!passwordNumberPattern.test(formData.password)) {
      toast.error('Password must contain at least two numbers.');
      setLoading(false)
      return;
    }
  
    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match.');
      setLoading(false)
      return;
    }
  
    // Check if terms and privacy policy are agreed to
    if (!formData.terms) {
      toast.error('You must agree to the Terms of Service.');
      setLoading(false)
      return;
    }
  
    if (!formData.priv) {
      toast.error('You must agree to the Privacy Policy.');
      setLoading(false)
      return;
    }

    const emailCheckResponse = await api.post(`${process.env.REACT_APP_API_URL}/if-email-exists`, { email });
    if (emailCheckResponse.data.message !== "email available") {
      toast.error("Email is already in use.");
      setLoading(false)
      return;
    }
  


    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
        email, parentFirstName, parentLastName, phoneNumber
    });

    setClientSecret(data?.clientSecret)
    setCurrentPage(3)
    setLoading(false)
  };
  
  






  const GetTwistedRiddles = async () => {
    setLoading(true)
        
    const timezone = moment.tz.guess();
        
    const TwistedRiddles = await api.post(`${process.env.REACT_APP_API_URL}/twisted-riddles`, {
        clientTimezone: timezone
    });
    setTwizzlerSupreme(TwistedRiddles.data)
    setLoading(false)
  }





  useEffect(() => {
    GetTwistedRiddles()
  }, []);

    
  const handleDivClick = (timeslot, details) => {   
    setSelectedTimeslot(timeslot);

    setFormData(prevFormData => ({
        ...prevFormData,
        classData: {
          ...prevFormData.details,
          studentTime: timeslot,
          studentTimeZone: details?.studentTimezone,
          
          teacherID: details?.teacherID,
          teacherTime: details?.teacherTime,
          teacherTimeZone: details?.teacherTimezone,
          studentDOW: details?.studentDayOfWeek,
          teacherDOW: details?.teacherDayOfWeek
        }
    }));
  };

  useEffect(()=> {
    setSelectedTimeslot(null)
  }, [selectedDayOfWeek])


  const goToPageTwo = () => {
    if (selectedTimeslot === null) {
        toast.error("Choose a time!")
        return;
    }

    setCurrentPage(2)
  }


  return (
    <div className='register-page'>
        <ToastContainer />
        <Navbar />

        {
            loading === true ?
            <div className='register-loading-container'>
                <Loader />
            </div>
            :
            <div className='register-container'>
                <div className='vertical-container'>
                        <img src={logo} className='register-logo' />

                        <div className='register-form'>

                            {
                                currentPage == 1 &&
                                <div className='page-one'>
                                    <h2>Step 1: Select your class time.</h2>

                                    <select value={selectedDayOfWeek} onChange={(e)=>setSelectedDayOfWeek(e.target.value)}>
                                        <option value={"monday"} >Monday</option>
                                        <option value={"tuesday"} >Tuesday</option>
                                        <option value={"wednesday"} >Wednesday</option>
                                        <option value={"thursday"} >Thursday</option>
                                        <option value={"friday"} >Friday</option>
                                        <option value={"saturday"} >Saturday</option>
                                    </select>


                    

                                    <div className='time-container'>
                                        { 
                                        twizzlerSupreme[selectedDayOfWeek] && Object.values(twizzlerSupreme[selectedDayOfWeek]).some(details => details.teacherID) ? (
                                            Object.entries(twizzlerSupreme[selectedDayOfWeek])
                                            .filter(([_, details]) => details?.teacherID)
                                            .map(([timeslot, details]) => (
                                                <div className='time-slot' key={timeslot} onClick={()=>handleDivClick(timeslot, details)}>
                                                    <input 
                                                        type="radio" 
                                                        name="radio" 
                                                        checked={selectedTimeslot === timeslot} 
                                                    />
                                                    <span>{timeslot}</span>
                                                </div>
                                            ))
                                        ) : (
                                            <div className='time-container'>
                                                <div className='unavailable-container'>
                                                    <h3>No current availabilities for this day.</h3>
                                                </div>
                                            </div>
                                        )
                                        }
                                    </div>



                                    <button className='nextButton' onClick={()=>goToPageTwo()} >Next Page</button>
                                </div>
                            }


                            {
                                currentPage == 2 &&
                                <div className='page-two'>

                                    <div className='page-two-top'>
                                        <div className='top-left'>
                                            <h2>Step 2: Make your student profile.</h2>
                                        </div>

                                        <div className='top-right'>
                                            <FaArrowLeft size={28} onClick={()=>setCurrentPage(1)} className='leftArrow' />
                                        </div>
                                    </div>

                                    <div className='info-form'>
                                        <h3>Parent Info</h3>

                                        <div className='input-container'>
                                            <input 
                                                placeholder='Parent First Name' 
                                                value={parentFirstName}
                                                onChange={handleChange('parentFirstName')}
                                            />

                                            <input 
                                                placeholder='Parent Last Name'
                                                value={parentLastName}
                                                onChange={handleChange('parentLastName')} 
                                            />
                                        </div>

                                        <div className='input-container'>
                                            <input 
                                                placeholder='Phone Number' 
                                                value={phoneNumber}
                                                onChange={handleChange('phoneNumber')}
                                            />

                                            <input 
                                                placeholder='Email Address' 
                                                value={email}
                                                onChange={handleChange('email')}
                                            />
                                        </div>

                                        <div className='input-container'>
                                            <input 
                                                placeholder='Street Address' 
                                                value={streetAddress}
                                                onChange={handleChange('streetAddress')}
                                            />

                                            <input 
                                                placeholder='City' 
                                                value={city}
                                                onChange={handleChange('city')}
                                            />
                                        </div>

                                        <div className='input-container'>
                                            <input 
                                                placeholder='State or Province' 
                                                value={stateProvince}
                                                onChange={handleChange('stateProvince')}
                                            />

                                            <input 
                                                placeholder='Country' 
                                                value={country}
                                                onChange={handleChange('country')}
                                            />
                                        </div>



                                        <h3>Student Info</h3>

                                        <div className='input-container'>
                                            <input 
                                                placeholder='Student First Name' 
                                                value={studentFirstName}
                                                onChange={handleChange('studentFirstName')}
                                            />

                                            <input 
                                                placeholder='Student Last Name' 
                                                value={studentLastName}
                                                onChange={handleChange('studentLastName')}
                                            />
                                        </div>


                                        <div className='birthday-container'>
                                            <p>Birthday</p>

                                            <input 
                                                type="date" 
                                                className='birthday' 
                                                value={birthday}
                                                onChange={handleChange('birthday')}
                                            />
                                        </div> 

                                        <h3>Password</h3>   
                                        <div className='input-container'>
                                            <input 
                                                placeholder='Password' 
                                                value={password}
                                                onChange={handleChange('password')}
                                            />

                                            <input 
                                                placeholder='Confirm Password' 
                                                value={confirmPassword}
                                                onChange={handleChange('confirmPassword')}
                                            />
                                        </div>   

                                        <div className='agreements'>
                                            <div className='checkboxContainer'>
                                                <input 
                                                    type="checkbox" 
                                                    checked={terms}
                                                    onChange={handleTermsChange}  
                                                />
                                                <label> I agree to the Terms of Service</label>
                                            </div>

                                            <div className='checkboxContainer'>
                                                <input 
                                                    type="checkbox" 
                                                    checked={priv}
                                                    onChange={handlePrivChange}
                                                />
                                                <label> I agree to the Privacy Policy</label>
                                            </div>
                                        </div>   

                                    </div>
                                    
                                    <button className='nextButton' onClick={()=>goToPageThree()} >Next Page <FaArrowRight className='arrow' /></button>
                                </div>
                            }

                            {
                                currentPage == 3 &&
                                <div className='page-three'>
                                    <div className='page-three-top'>
                                        <div className='top-left'>
                                            <h2>Step 3: Blast Off!</h2>
                                        </div>

                                        <div className='top-right'>
                                            <FaArrowLeft size={28} onClick={()=>setCurrentPage(2)} className='leftArrow' />
                                        </div>
                                    </div>

                                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                                        <Pay 
                                            clientSecret={clientSecret} 
                                            formData={formData}
                                            loading={loading}
                                            setLoading={setLoading}
                                        />
                                    </Elements>
                                </div>
                            }

                        </div>

                        <div className='options-container'>
                            <p className='yesaccount'>Already have an account? <span onClick={()=>navigate("/login")} className='loginnow'>Login</span></p>

                            <ul className='options-container-list'>
                                <li onClick={()=>navigate("/forgot-password")} >Home</li>

                                <li onClick={()=>navigate("/contact")} >Contact Us</li>

                                <li onClick={()=>navigate("/terms-of-service")} >Terms of Service</li>
                            </ul>
                        </div>
                </div>
            </div>
        }

        <Footer />
    </div>
  )
}

export default Register