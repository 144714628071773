import React, { useEffect, useRef } from 'react';
import './heroBanner.css';
import Navbar from '../navbar/navbar';
import clouds from '../../images/clouds.png';

const HeroBanner = () => {


  return (
    <div className="hero-banner">
      <Navbar />
      <h1 className="tagline">
        We turn smart kids into<br />
        <span className="software-engineers">software engineers.</span>
      </h1>
      <img src={clouds} className="hero-image" alt="Clouds Background" />
    </div>
  );
};

export default HeroBanner;
