import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';
import { ToastContainer, toast } from 'react-toastify';
import './reset.css';
import api from '../../api/api';

const Reset = () => {
  var [loading, setLoading] = useState();
  var [password, setPassword] = useState("");
  var [confirmPassword, setConfirmPassword] = useState("");
  var navigate = useNavigate();

  const submitPassword = async () => {
    setLoading(true)

    const token = window.location.href.split('/')[6];
    if (password == "" || confirmPassword == "") {
        toast.error("Fill all fields!")
        setLoading(false)
        return;
    }

    if (password != confirmPassword) {
        toast.error("Passwords must match!")
        setLoading(false)
        return
    }

    if (password == confirmPassword && password != "" && confirmPassword != "") {
        await api.post(`${process.env.REACT_APP_API_URL}/reset`, {
            resetPasswordLink: token, newPassword: password
        }).then((res) => {
            toast.success("Password reset!")
            setLoading(false)
            return
        }).catch((err) => {
            console.log(err)
            toast.error("Error!")
            setLoading(false)
            return
        })
    }
  }

  return (
    <div className='reset-password-page'>
        <ToastContainer />
        <Navbar />
        
        {
            loading === true ?
            <div className='loading-container'>
                <Loader />
            </div>
            :
            <div className='reset-password-wrapper'>
                <div className='reset-password-form'>
                    <h1>
                    Pick a new password to get you back in action!
                    </h1>

                    <input value={password} onChange={(e)=>setPassword(e.target.value)} type="password" placeholder='New password' />

                    <input value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} type="password" placeholder='Confirm new password' />

                    <button onClick={()=>submitPassword()} class="bolt-button">
                        <svg
                        viewBox="0 0 16 16"
                        class="bi bi-lightning-charge-fill"
                        fill="currentColor"
                        height="16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"
                        ></path></svg>
                        Reset Password
                    </button>
                </div>
            </div>
        }


        <Footer />
    </div>
  )
}

export default Reset