import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/klc-logo.png';
import './footer.css';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className='footer'>
        <div className='footer-left'>
            <img src={logo} className='footer-logo' />
        </div>

        <div className='footer-right'>
            <h3>Resources</h3>

            <p onClick={()=>navigate("/terms-of-service")} className='resource-item'>
            Terms of Service
            </p>

            <p onClick={()=>navigate("/reschedule-policy")} className='resource-item'>
            Reschedule Policy
            </p>

            <p onClick={()=>navigate("/privacy-policy")} className='resource-item'>
            Privacy Policy
            </p>
        </div>
    </div>
  )
}

export default Footer