import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import logo from '../../images/klc-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../components/loader/loader';
import './login.css';
import api from '../../api/api';

const Login = () => {
  const navigate = useNavigate();
  var [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password1: ''
  });
  
  // Handle change from inputs
  const { email, password1 } = formData;
  const handleChange = text => e => {
    setFormData({ ...formData, [text]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (email && password1) {
      try {
        const response = await api.post(
          `${process.env.REACT_APP_API_URL}/login`, 
          {
            email,
            password: password1
          });
        
  
        setLoading(false);

        if (response.data.success) {
          // Store token in LocalStorage or SessionStorage
          localStorage.setItem('authToken', response.data.token);
      
          if (window.location.pathname.split("/")[2] === "progress") {
            window.location.href = `${response.data.redirectTo}my-progress?token=${response.data.token}`;
          } else {
            // Redirect to the subdomain or other domain
            window.location.href = `${response.data.redirectTo}?token=${response.data.token}`;
            //window.location.href = `http://localhost:3000/?token=${response.data.token}`;
          }
        } else {
          // Handle case where login is not successful but no error was thrown
          toast.error('Login unsuccessful. Please try again.');
        }
  
        // Optionally, clear form data
        setFormData({
          ...formData,
          email: '',
          password1: ''
        });
  
      } catch (err) {
        setLoading(false);
        setFormData({
          ...formData,
          email: '',
          password1: ''
        });
        // Display error message from server or a generic error message
        toast.error('Invalid Login Credentials');
      }
    } else {
      setLoading(false);
      toast.error('Please fill in all fields');
    }
  };



  return (
    <div className='login-page'>
        <ToastContainer />
        <Navbar />

        {
            loading === true ?
            <div className='login-loading-container'>
                <Loader />
            </div>
            :
            <div className='login-container'>
                <div className='vertical-container'>
                    <img src={logo} className='login-logo' />

                    <div className='login-form'>
                        <h2>Sign in to your account</h2>

                        <div className='login-email-container'>
                            <p>Email:</p>
                            <input type='text' onChange={handleChange('email')} value={email} />
                        </div>

                        <div className='login-password-container'>
                            <p>Password:</p>
                            <input type="password" onChange={handleChange('password1')} value={password1} />
                        </div>

                        <button onClick={handleSubmit} >Let's Start</button>
                    </div>

                    <div className='options-container'>
                        <p className='noaccount'>Don't have an account? <span onClick={()=>navigate("/register")} className='startnow'>Start Now</span></p>

                        <ul className='options-container-list'>
                            <li onClick={()=>navigate("/forgot-password")} >Forgot Password?</li>

                            <li onClick={()=>navigate("/contact")} >Contact Us</li>

                            <li onClick={()=>navigate("/terms-of-service")} >Terms of Service</li>
                        </ul>
                    </div>
                </div>
            </div>
        }


        <Footer />
    </div>
  )
}

export default Login