import React from 'react';
import './home.css';
import HeroBanner from '../../components/hero/heroBanner';
import One from '../../components/one/one';
import Two from '../../components/two/two';
import Three from '../../components/three/three';
import Wonderful from '../../components/wonderful/wonderful';
import Footer from '../../components/footer/footer';

const Home = () => {
  return (
    <div className='homepage'>
        <HeroBanner />


        <One />

        <Two />
        
        <Three />


        <Footer />
    </div>
  )
}

export default Home