import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home/home';
import Contact from './pages/contact/contact';
import Terms from './pages/terms/terms';
import PrivacyPolicy from './pages/privacy-policy/privacyPolicy';
import ReschedulePolicy from './pages/reschedule-policy/reschedulePolicy';
import Login from './pages/login/login';
import Register from './pages/register/register';
import ForgotPassword from './pages/forgot-password/forgotPassword';
import Reset from './pages/reset/reset';
import Success from './pages/success/success';
import Warning from './pages/warning/warning';

function App() {
  return (
    <Router>
      <Routes>

        <Route exact path="/" element={<Home />} />

        <Route exact path="/login/*" element={<Login />} />

        <Route exact path="/register" element={<Register />} />

        <Route exact path="/register/success" element={<Success />} />

        <Route exact path="/register/warning" element={<Warning />} />

        <Route exact path="/forgot-password" element={<ForgotPassword />} />

        <Route exact path={`/users/password/reset/:id`} element={<Reset />} />

        <Route exact path="/contact" element={<Contact />} />

        <Route exact path="/terms-of-service" element={<Terms />} />

        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route exact path="/reschedule-policy" element={<ReschedulePolicy />} />

      </Routes>
    </Router>
  );
}

export default App;
