import React from 'react';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import './reschedulePolicy.css';

const ReschedulePolicy = () => {
  return (
    <div className='reschedule-policy-page'>
      <Navbar />
      <div className="rr__container">
        <h1>
        Reschedule Policy for Kids Learn Coding LLC
        </h1>

        <div className='toc'>
              <h3>Table of Contents</h3>
              <ol>
                <li><a href="#introduction">Introduction</a></li>
                <li><a href="#howTo">How to Reschedule a Class</a></li>
                <li><a href="#NoReturnsorRefunds">No Returns or Refunds</a></li>
                <li><a href="#24HourAdvanceNoticeRequired">24-Hour Advance Notice Required</a></li>
                <li><a href="#NoReschedulesforMissedClasses">No Reschedules for Missed Classes</a></li>
                <li><a href="#LimitonRescheduling">Limit on Rescheduling Already Rescheduled Classes</a></li>
                <li><a href="#TechnicalIssues">Technical Issues</a></li>
                <li><a href="#TeacherAvailability">Teacher Availability</a></li>
                <li><a href="#SlotAvailability">Slot Availability</a></li>
                <li><a href="#HolidaysandNonBusinessDays">Holidays and Non-Business Days</a></li>
                <li><a href="#FinalLessonsofCourse">Final Lessons of a Course</a></li>
                <li><a href="#OrganizationInitiatedRescheduling">Organization-Initiated Rescheduling</a></li>
              </ol>
            </div>

        <h3 id="introduction">
        1. Introduction
        </h3>
        <p>
        Welcome to the Reschedule Policy for Kids Learn Coding LLC ("Company", "we", "us", "our"). We strive to provide a flexible and accommodating learning environment for all our students. Please note that reschedules are only permitted if requested at least 24 hours in advance of the desired rescheduled class time. It is important for students and parents to be aware that classes missed or not attended by the student are non-refundable. This policy helps us ensure that our teachers can prepare effectively and that all students can enjoy a consistent and uninterrupted learning experience. It's important to note that reschedules are not guaranteed and are subject to availability.
        </p>

        <h3 id="howTo">
        2. How to Reschedule a Class
        </h3>
        <p>
        Should the situation arise where you want to reschedule a specific class time, log into your KLC account and navigate to the "Join Meeting" page. From there click the link that prompts you to reschedule a class and follow the steps until you get to the final page where you submit your request. Please note reschedules are not garunteed and are subject to teacher availability.
        </p>

        <h3 id="NoReturnsorRefunds">
        3. No Returns or Refunds
        </h3>
        <p>
        All sales of products and services provided by Kids Learn Coding LLC are final. We do not offer returns or refunds for any of our products or services, including group or private lessons. By purchasing our products or services, you agree to this no return or refund policy.
        </p>

        <h3 id="24HourAdvanceNoticeRequired">
        4. 24-Hour Advance Notice Required
        </h3>
        <p>
        All rescheduling requests must be made at least 24 hours in advance of the scheduled class time. Requests submitted less than 24 hours before the class are not permitted and the class will be considered missed and non-refundable.
        </p>

        <h3 id="NoReschedulesforMissedClasses">
        5. No Reschedules for Missed Classes
        </h3>
        <p>
        If a student fails to attend a scheduled class without the required 24-hour notice, the class cannot be rescheduled and fees paid are non-refundable.        
        </p>

        <h3 id="LimitonRescheduling">
        6. Limit on Rescheduling Already Rescheduled Classes
        </h3>
        <p>
        Classes that have already been rescheduled once cannot be rescheduled again. This ensures that both students and teachers can rely on a stable and predictable schedule.
        </p>

        <h3 id="TechnicalIssues">
        7. Technical Issues
        </h3>
        <p>
        If technical issues prevent a student from successfully submitting a reschedule request within the required timeframe, the reschedule will not be permitted. It is advisable for students to ensure they are attempting to reschedule well before the 24-hour deadline to prevent such issues.
        </p>

        <h3 id="TeacherAvailability">
        8. Teacher Availability
        </h3>
        <p>
        Reschedules are subject to the availability of the teacher. If no suitable times are available with the teacher, the reschedule request cannot be accommodated.
        </p>

        <h3 id="SlotAvailability">
        9. Slot Availability
        </h3>
        <p>
        Rescheduling is dependent on the availability of open slots within the program's schedule. If there are no available slots that suit the student’s needs, the reschedule request will not be granted.
        </p>

        <h3 id="HolidaysandNonBusinessDays">
        10. Holidays and Non-Business Days
        </h3>
        <p>
        Reschedules that fall on holidays or non-business days, when Kids Learn Coding LLC does not operate, will not be allowed. If a student’s original class time falls on a holiday, it is the responsibility of the parent or guardian to reschedule the class at least 24 hours prior to the originally scheduled time.
        </p>

        <h3 id="FinalLessonsofCourse">
        11. Final Lessons of a Course
        </h3>
        <p>
        Classes scheduled as the final lesson of a course cannot be rescheduled, as they may interfere with the completion and assessment processes.
        </p>

        <h3 id="OrganizationInitiatedRescheduling">
        12. Organization-Initiated Rescheduling
        </h3>
        <p>
        In cases where a teacher is unexpectedly unable to conduct a class, Kids Learn Coding LLC reserves the right to reschedule the class. Affected students will be notified as soon as possible and provided with alternative class times. We prioritize maintaining the continuity of scheduled courses and minimizing inconvenience.
        </p>

      </div>
      <Footer />
    </div>
  )
}

export default ReschedulePolicy