import React, { useState } from 'react';
import "./contact.css";
import Navbar from '../../components/navbar/navbar';
import { IoIosArrowDown } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../api/api'

const Contact = () => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false)

  const toggleQuestion = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  
  const faqData = [
    {
      question: "Can we reschedule a class?",
      answer: "Yes, you can reschedule a class by contacting support 24 hours before the class."
    },
    {
      question: "Can I have a free class?",
      answer: "We offer a free trial class for first-time users. Contact us for more details."
    },
    {
      question: "How do I join a class?",
      answer: "After registration, you will receive a link via email to join the class. After registration, you will receive a link via email to join the class. After registration, you will receive a link via email to join the class. After registration, you will receive a link via email to join the class. After registration, you will receive a link via email to join the class. After registration, you will receive a link via email to join the class."
    },
    {
      question: "What is the refund policy?",
      answer: "You can request a refund within 7 days of the purchase if you’re not satisfied."
    }
  ];

  const handleSubmit = async () => {
    if (email == '' || message == '') {
      toast.error("Fill all fields.")
    } else {
      setLoading(true)
      await api.post(`${process.env.REACT_APP_API_URL}/submit-inquiry`, {
        customerEmail: email, message
      }).then((res) => {
         setLoading(false)
         toast.success('Success, we will reach out via email!')
      }).catch((err) => {
        console.log(err)
        toast.error('Error,try again later.')
        setLoading(false)
      })
    }
  }

  return (
    <div className='contact-page'>
      <ToastContainer />
        <Navbar />

        {
          loading === true ?
          <div className='contact-loading'>
            <Loader />
          </div>
          :
          <div className='contact-body'>
            <div className='contact-left'>
                <h1>F.A.Q</h1>

                <div className='questions'>
                    {faqData.map((item, index) => (
                    <div key={index} className='question'>

                        <div className='question-header' onClick={() => toggleQuestion(index)}>
                            <p>{item.question}</p>
                            <IoIosArrowDown
                                size={32}
                                className={`arrow-icon ${expandedQuestion === index ? "rotate" : ""}`}
                            />
                        </div>

                        <div className={`answer ${expandedQuestion === index ? "show" : ""}`}>
                            <p>{item.answer}</p>
                        </div>
                    </div>
                    ))}
                </div>


                <div className='display-card-containers'>
                    <div className='contact-card-one'>
                        <h3>Customer Support <FaPhoneAlt className='support-icon' size={18} /></h3>

                        <p>
                          Reach out to our customer support team any time! Our friendly space cadets are here to help.
                        </p>
                    </div>

                    <div className='contact-card-two'>
                      <h3>F.A.Q <HiOutlineSpeakerphone className='support-icon' size={20} /></h3>

                      <p>
                      Looking for answers? Check out our FAQs above! We’ve covered the most common questions to help you find a solution quickly. If you still need assistance, send us a message!
                      </p>
                    </div>
                </div>
            </div>

            <div className='contact-right'>
                <h1 className='contact-header'>Contact Us</h1>

                <p className='contact-prompt'>
                    Hi friend, we're always here to help. If you have any questions or concerns submit a inquiry below.  
                </p>

                

                <div className='email-box-container'>
                    <p>Email:</p>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" />
                </div>

                <div className='message-box-container'>
                    <p>Message:</p>
                    <textarea value={message} onChange={(e)=>setMessage(e.target.value)} ></textarea>
                </div>

                <button onClick={()=>handleSubmit()} className='submit-button'>Submit</button>
            </div>
          </div>
        }

        <Footer />
    </div>
  )
}

export default Contact