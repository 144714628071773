import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar';
import './forgotPassword.css';
import Footer from '../../components/footer/footer';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../components/loader/loader';
import api from '../../api/api';

const ForgotPassword = () => {
  const navigate = useNavigate();
  var [loading, setLoading] = useState(false)
  var [email, setEmail] = useState("");

  const submitEmail = async () => {
    setLoading(true)
    await api.post(`${process.env.REACT_APP_API_URL}/forgot`, {
        email
    }).then((res) => {
        toast.success(`Email sent to ${email}`)
        setLoading(false)
    }).catch((err) => {
        toast.error("Enter email you signed up with!")
        setLoading(false)
    })
  }


  return (
    <div className='forgot-password-page'>
        <ToastContainer />
        <Navbar />
        
        {
            loading === true ?
            <div className='loading-container'>
                <Loader />
            </div>
            :
            <div className='forgot-password-wrapper'>
                <div className='forgot-password-form'>
                    <h1>
                    Enter your account email,<br/>and we’ll zap you a reset link!            
                    </h1>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" placeholder='Email Address' />

                    <button onClick={()=>submitEmail()} class="bolt-button">
                        <svg
                        viewBox="0 0 16 16"
                        class="bi bi-lightning-charge-fill"
                        fill="currentColor"
                        height="16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"
                        ></path></svg>
                        Reset Password
                    </button>

                </div>
            </div>
        }


        <Footer />
    </div>
  )
}

export default ForgotPassword