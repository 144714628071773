import React from 'react'
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'
import './warning.css';

const Warning = () => {
  const navigate = useNavigate();

  return (
    <div className='warning-page'>
        <ToastContainer />
        <Navbar />

        <div className='warning-container'>
            <h1>
                <span className='attention'>⚠️Attention⚠️</span>
                <br/>Your payment went through but appointment was not booked due to a server error. Please contact customer support to resolve this issue, they are on standby to help. Click the following link to chat with them: <a onClick={()=>navigate("/contact")} >Customer Support</a>
            </h1>
        </div>

        <Footer />
    </div>
  )
}

export default Warning