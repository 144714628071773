import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import './terms.css';

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className='terms-page'>
        <Navbar />

        <div className='termOfService__container'>
            <h3>
            Terms of Service for Kids Learn Coding LLC
            </h3>

            <h3>
            Last Updated: March 25, 2024
            </h3>

            <div className='toc'>
              <h3>Table of Contents</h3>
              <ol>
                <li><a href="#introduction">Introduction</a></li>
                <li><a href="#acceptanceOfTerms">Acceptance of Terms</a></li>
                <li><a href="#changesToTerms">Changes to Terms</a></li>
                <li><a href="#privacyPolicy">Privacy Policy and Protecting Children’s Privacy</a></li>
                <li><a href="#accountRegistration">Account Registration and Responsibilities</a></li>
                <li><a href="#subscriptionDetails">Subscription Details and Payment Terms</a></li>
                <li><a href="#userConduct">User Conduct and Safety</a></li>
                <li><a href="#lessonScheduling">Lesson Scheduling and Rescheduling</a></li>
                <li><a href="#refundPolicy">Refund Policy</a></li>
                <li><a href="#thirdPartyServices">Third-Party Services and Platforms</a></li>
                <li><a href="#recordedSessions">Recorded Sessions</a></li>
                <li><a href="#contentOwnership">Content Ownership and Use Rights</a></li>
                <li><a href="#intellectualProperty">Intellectual Property Rights in Student Creations</a></li>
                <li><a href="#technologyRequirements">Technology Requirements</a></li>
                <li><a href="#codeOfConduct">Code of Conduct</a></li>
                <li><a href="#linksToThirdParty">Links to Third-Party Sites</a></li>
                <li><a href="#disclaimerOfWarranties">Disclaimer of Warranties and Accuracy of Materials</a></li>
                <li><a href="#limitationOfLiability">Limitation of Liability</a></li>
                <li><a href="#disputeResolution">Dispute Resolution</a></li>
                <li><a href="#terminationOfUse">Termination of Use</a></li>
                <li><a href="#feedbackAndCopyright">Feedback and Copyright Complaints</a></li>
                <li><a href="#governingLaw">Governing Law</a></li>
                <li><a href="#ageRequirements">Age Requirements</a></li>
                <li><a href="#contactInformation">Contact Information</a></li>
              </ol>
            </div>



            <h1 id="introduction" >
            1. Introduction
            </h1>
            <p>
            Welcome to Kids Learn Coding LLC, accessible at kidslearncoding.org (hereafter referred to as "the Website"). We are dedicated to providing engaging, educational, and interactive coding lessons for children. Our mission is to empower the next generation with the knowledge and skills to thrive in a digital world, fostering creativity, critical thinking, and problem-solving through coding.
            </p>
            <br/>
            <p>
            Kids Learn Coding LLC offers a range of online coding courses, projects, and activities designed specifically for children. Through our platform, students can log in to their accounts, access course materials, participate in live coding sessions with qualified instructors, and engage in hands-on project building using tools such as Scratch, Roblox Studio, and Visual Studio Code. Our services also include the use of third-party platforms, like Zoom, to facilitate interactive, remote learning experiences.
            </p>
            <br/>
            <p>
            By accessing and using the Website, you (the user or guardian of the student user) agree to be bound by these Terms of Service (ToS), which form a legally binding agreement between you and Kids Learn Coding LLC. These terms outline your rights and responsibilities, as well as those of Kids Learn Coding LLC, regarding the use of our Website and participation in our courses.
            </p>
            <br/>
            <p>
            We invite you to read these Terms of Service carefully before using the Website. Your continued use of the Website signifies your acceptance of these terms and any changes made to them in the future. If you are the parent or guardian of a child using the Website, you agree to these Terms of Service on behalf of your child and are responsible for monitoring and guiding your child's use of the Website.
            </p>
            <br/>
            <p>
            Thank you for choosing Kids Learn Coding LLC as your partner in coding education. We are excited to embark on this coding journey with you and your child.
            </p>

            <h1 id="acceptanceOfTerms" >
            2. Acceptance of Terms
            </h1>
            <p>
            By accessing, browsing, or using the Kids Learn Coding LLC website, located at kidslearncoding.org, and all associated services, including but not limited to our online coding courses, interactive lessons, and project-building activities (collectively, the "Services"), you, the user or the parent/guardian of a minor user (referred to as "you" or "your"), acknowledge that you have read, understood, and agree to be bound by these Terms of Service (ToS). If you are entering into this agreement on behalf of a minor, you represent and warrant that you are the legal parent or guardian of the minor and have the authority to bind the minor to these Terms of Service.
            </p>
            <br/>
            <p>
            <strong>Consent and Capacity:</strong> By using our Services, you affirm that you are either more than 18 years of age, an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these ToS, and to abide by and comply with these ToS.
            </p>
            <br/>
            <p>
            <strong>Account Registration:</strong> Certain features of our Services, including accessing course content and participating in interactive lessons, require you to create an account. By creating an account, you agree to provide accurate, current, and complete information as prompted by our registration processes. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. You agree to notify Kids Learn Coding LLC immediately of any unauthorized use of your account or any other breach of security.
            </p>
            <br/>
            <p>
            <strong>Minor Users:</strong> If the Services are being used by a minor, the parent or guardian agrees to guide, supervise, and be responsible for the minor's use of the Website and Services. This includes monitoring the minor's access and use of the internet and ensuring that the content accessed is appropriate. The parent or guardian acknowledges that Kids Learn Coding LLC may provide content and learning materials intended for educational purposes but is not responsible for screening or monitoring the content for minors.
            </p>
            <br/>
            <p>
            <strong>Amendments to Terms:</strong> Kids Learn Coding LLC reserves the right, at its sole discretion, to change, modify, add, or remove portions of these Terms of Service at any time without prior notice. It is your responsibility to check these Terms of Service periodically for changes. Your continued use of the Services following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms of Service, Kids Learn Coding LLC grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the Services.
            </p>
            <br/>
            <p>
            <strong>Electronic Communications:</strong> By using the Services, you consent to receive electronic communications from Kids Learn Coding LLC, which may include emails, phone calls, and text messages to the email address and phone number you provided during registration or subsequently updated in your account. These communications will relate to your account and may involve sending service announcements, administrative messages, and other information related to your use of the Services. Standard text messaging rates may apply as provided by your mobile carrier. You have the option to opt-out of receiving text messages from us. However, opting out of receiving all types of electronic communications may impact your use of the Services, as certain communications are an integral part of the Services we provide. By agreeing to these Terms of Service, you acknowledge that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
            </p>
            <br/>
            <p>
            <strong>Termination:</strong> Kids Learn Coding LLC reserves the right to terminate your access to all or any part of the Services at any time, with or without cause, with or without notice, effective immediately.
            </p>
            <br/>
            <p>
            By accessing or using the Services, you signify your acceptance of these Terms of Service and agree to be bound by them in full. If you do not agree to these Terms of Service or cannot comply with them, you must not access or use the Services.
            </p>

            <h1 id="changesToTerms" >
            3. Changes to Terms
            </h1>
            <p>
            Kids Learn Coding LLC ("we," "us," "our") reserves the right, at our sole discretion, to revise, update, or otherwise modify these Terms of Service ("Terms") at any time. Such changes may reflect updates to our services, legal requirements, or adjustments in our policies. It is important for users ("you," "your") to stay informed about these changes, as they may affect your rights and obligations regarding the use of our website, kidslearncoding.org, and our associated services (collectively, the "Services").
            </p>
            <br/>
            <p>
            <strong>Effective Date of Changes:</strong> Changes to these Terms will become effective immediately upon posting unless otherwise specified. In certain cases, we may specify a later effective date for the changes. We encourage you to review the updated Terms carefully. Your continued use of the Services after changes have been posted or after the effective date of changes will constitute your acceptance of such changes. If you do not agree to the new terms, you should stop using the Services.
            </p>
            <br/>
            <p>
            <strong>Responsibility to Review Changes:</strong> It is your responsibility to regularly check the Terms of Service for updates. Your ongoing review of the Terms ensures that you are always aware of the current terms that apply to your use of the Services.
            </p>
            <br/>
            <p>
            By continuing to access or use the Services after revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new terms, you are not authorized to use the Services, and you should discontinue your use immediately.
            </p>

            <h1 id="privacyPolicy" >
            4. Privacy Policy and Protecting Children’s Privacy
            </h1>
            <p>
            Kids Learn Coding LLC, accessible via kidslearncoding.org, is deeply committed to safeguarding the privacy of our users, with special attention to the safety and privacy of children. This commitment is reflected in our Privacy Policy, which outlines our practices for collecting, using, and disclosing personal information. Given the importance of privacy, particularly for our younger users, we have developed a comprehensive Privacy Policy separate from these Terms of Service.
            </p>
            <br/>
            <p>
            <strong>Referencing Our Privacy Policy:</strong> For detailed information regarding the collection, use, and protection of your personal information, including that of children under the age of 13, we encourage you to review our Privacy Policy, which can be found by clicking <span onClick={()=>navigate("/privacy-policy")} className='link'>this link</span>.
            </p>
            <br/>
            <p>
              <strong>The Privacy Policy elaborates on the following key areas:</strong>
            </p>
            <br/>
            <ul>
              <li>
              <strong>Types of Information Collected:</strong> Including both personal and technical information, collected through various interactions with our website and services.
              </li>

              <li>
              <strong>Use of Collected Information:</strong> How we use the information gathered to enhance the user experience and provide personalized educational content.
              </li>

              <li>
              <strong>Cookies and Tracking Technologies:</strong> Explanation of how we use cookies and other tracking technologies to improve our services and your experience on our site.
              </li>

              <li>
              <strong>Children’s Privacy:</strong> Our adherence to laws protecting children's online privacy, particularly the Children's Online Privacy Protection Act (COPPA), and the steps we take to ensure compliance, including obtaining verifiable parental consent when necessary.
              </li>

              <li>
              <strong>Data Security Measures:</strong> The measures we implement to protect the security of your personal information against unauthorized access, alteration, or destruction.
              </li>
            </ul>

            <br/>
            <p>
            To access the Privacy Policy, please visit the following link: <span onClick={()=>navigate("/privacy-policy")} className='link'>Privacy Policy.</span> This document is essential reading for all users of our website and services, as it forms a part of the agreement between you and Kids Learn Coding LLC. By using our services, you acknowledge that you have read, understood, and agreed to the terms outlined in our Privacy Policy.
            </p>
            <br/>
            <p>
            <strong>Protecting Children’s Privacy:</strong> Protecting the privacy of young children is especially important to us. We do not knowingly collect personal information from children under the age of 13 without obtaining verifiable parental consent in advance. Our Privacy Policy provides detailed information on our practices regarding children’s privacy, including how parents can review, manage, or delete their child’s information.
            </p>
            <br/>
            <p>
            <strong>Changes and Updates to the Privacy Policy:</strong> Our Privacy Policy may be updated from time to time to reflect changes in our practices, technology, or legal requirements. We will notify users of any significant changes by posting the updated policy on our website, along with its effective date. We encourage users and guardians to review our Privacy Policy regularly to stay informed about our privacy practices.
            </p>
            <br/>
            <p>
              <strong>Contacting Us:</strong> If you have any questions or concerns about our Privacy Policy or the protection of personal information, please do not hesitate to contact us. Our contact information can be found on our website, allowing you to reach out directly for assistance.
            </p>

            <h1 id="accountRegistration" >
            5. Account Registration and Responsibilities
            </h1>
            <p>
            At Kids Learn Coding LLC, accessible via kidslearncoding.org, we offer a vibrant, interactive learning environment where children can explore the fascinating world of coding. To participate in our online coding courses, projects, and activities, users are required to create an account. This section outlines the process for account registration and the responsibilities of account holders, ensuring a safe and productive learning experience for all.
            </p>
            <br/>
            <p>
              <strong>Account Registration Process</strong>
            </p>
            <br/>
            <p>
            <strong>Creating an Account:</strong> To create an account on kidslearncoding.org, you will be asked to provide certain personal information, such as your name, email address, and age. If the account is being created for a child under the age of 13, it is required that a parent or guardian completes the registration process, providing their own information where necessary and consenting to the collection and use of the child’s information as detailed in our Privacy Policy.
            </p>
            <br/>
            <p>
            <strong>Account Information:</strong> When registering for an account, it is crucial to provide accurate, complete, and current information. Failure to do so constitutes a breach of the Terms of Service, which may result in immediate termination of your account on our service.
            </p>
            <br/>
            <p>
            <strong>Passwords:</strong> Upon creating an account, you will select a password. You are responsible for safeguarding the password that you use to access our services and for any activities or actions under your password. We encourage the use of "strong" passwords (passwords that use a combination of upper and lower case letters, numbers, and symbols) to enhance account security. Should you suspect that your password has been compromised, it is your responsibility to notify us promptly and change your password.
            </p>
            <br/>
            <p>
              <strong>Responsibilities of Account Holders</strong>
            </p>
            <br/>
            <p>
            <strong>Supervision of Minor’s Use:</strong> If the account is created for a child under the age of 13, the parent or guardian agrees to supervise and be responsible for all activities that occur under the account. Parents and guardians should guide their children in using the website responsibly, monitoring their access to and interaction with the site.
            </p>
            <br/>
            <p>
            <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our service or a third-party service.
            </p>
            <br/>
            <p>
            <strong>Reporting Unauthorized Use:</strong> You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
            </p>
            <br/>
            <p>
            <strong>Account Activity:</strong> You are responsible for all content (e.g., comments, posts, uploads) and activities that occur under your account. Kids Learn Coding LLC reserves the right to terminate accounts that are inactive for an extended period or are found to be repeatedly engaged in activities that violate our Terms of Service or Code of Conduct.
            </p>
            <br/>
            <p>
            <strong>Data Accuracy:</strong> Keeping your registration information accurate and up-to-date is essential for us to serve you better. If any of your information changes, please update your account details promptly.
            </p>
            <br/>
            <p>
            <strong>Termination and Deletion:</strong> You have the right to terminate your account at any time. Upon termination, you may also request the deletion of your account and associated data, in accordance with our Privacy Policy.
            </p>
            <br/>
            <p>
              <strong>Acceptance of Terms</strong>
            </p>
            <br/>
            <p>
            By completing the registration process and creating an account with Kids Learn Coding LLC, you acknowledge that you have read, understood, and agree to comply with these responsibilities. Furthermore, you affirm that any registration information you submit to Kids Learn Coding LLC is true, accurate, and complete, and you consent to be bound by and abide by the Terms of Service.
            </p>



            <h1 id="subscriptionDetails" >
              6. Subscription Details and Payment Terms
            </h1>
            <p>
            At Kids Learn Coding LLC, accessible via kidslearncoding.org, we offer a subscription-based service that provides access to a comprehensive range of online coding courses, projects, and interactive lessons designed specifically for children. This section outlines the details of our subscription model, including payment terms and conditions, to ensure a clear understanding of the financial commitments associated with accessing our services.
            </p>
            <br/>
            <p>
            <strong>Monthly Subscriptions:</strong> Our services are offered through a monthly subscription model. Upon subscribing, users will have access to the full suite of educational materials, live coding sessions, and interactive projects available on our platform for the duration of their subscription period.
            </p>
            <br/>
            <p>
            <strong>Free Trial Period:</strong> New subscribers may be eligible for a free trial period, during which they can access all subscription-based services without charge. Details of the free trial period, including its duration, will be provided at the time of subscription. Unless canceled at least 24 hours before the trial period ends, the subscription will automatically renew as a paid subscription.
            </p>
            <br/>
            <p>
            <strong>Payment Processing:</strong> All subscription payments are processed securely through Stripe, a third-party payment processing service. By subscribing to our services, you agree to allow Stripe to process your payment information. Details of Stripe's terms of service and privacy policy can be found on their website.
            </p>
            <br/>
            <p>
            <strong>Monthly Charges:</strong> Subscription fees are charged on a monthly basis. The charge will occur on the same date each month, corresponding to the commencement date of your subscription. If the initial payment date falls on a date not contained in a given month, you will be charged on the last day of such month.
            </p>
            <br/>
            <p>
            <strong>Updating Payment Information:</strong> It is your responsibility to ensure that all payment information is current and accurate. If payment cannot be processed due to outdated or incorrect payment information, your subscription may be suspended until payment has been successfully processed.
            </p>
            <br/>
            <p>
            <strong>Price Changes:</strong> Kids Learn Coding LLC reserves the right to adjust subscription prices at any time. However, any price changes will be communicated to subscribers at least 30 days in advance, and will not affect the current billing cycle.
            </p>
            <br/>
            <p>
            <strong>Cancellations:</strong> Subscriptions can be canceled at any time. Upon cancellation, subscribers will retain access to their subscription services until the end of the current billing period. No partial refunds will be provided for any remaining days in a subscriber's billing cycle.
            </p>
            <br/>
            <p>
            <strong>Refunds:</strong> All payments are non-refundable, and no refunds or credits will be provided for partial subscription periods or unused services. Exceptions to this policy may be considered on a case-by-case basis at the discretion of Kids Learn Coding LLC.
            </p>
            <br/>
            <p>
            <strong>Failed Payments:</strong> In the event of a failed payment, subscribers will be notified and given the opportunity to update their payment information. If payment cannot be successfully processed within a reasonable time frame determined by Kids Learn Coding LLC, the subscription may be terminated.
            </p>
            <br/>
            <p>
            By subscribing to our services, you agree to these Subscription Details and Payment Terms. You authorize Kids Learn Coding LLC to charge your chosen payment method through Stripe for subscription fees on a monthly basis until your subscription is canceled in accordance with these terms.
            </p>


            <h1 id="userConduct" >
            7. User Conduct and Safety
            </h1>
            <p>
            At Kids Learn Coding LLC, accessible via kidslearncoding.org, we are dedicated to creating a safe, respectful, and positive learning environment for all users, especially children. This section outlines our expectations regarding user conduct and the measures we take to ensure the safety of our online community. By accessing and using our services, users agree to adhere to these guidelines and help maintain an environment conducive to learning and exploration.
            </p>
            <br/>
            <p>
            <strong>Respectful Interaction:</strong> All users are expected to interact with others respectfully and kindly. Bullying, harassment, or any form of abuse will not be tolerated. This includes, but is not limited to, name-calling, discrimination, threats, or sharing of inappropriate content.
            </p>
            <br/>
            <p>
            <strong>Appropriate Content:</strong> Users are expected to share and create content that is appropriate for a learning environment and suitable for children. The posting of harmful, obscene, explicit, or violent content is strictly prohibited. Content should be relevant to the course material and designed to foster a positive learning experience.
            </p>
            <br/>
            <p>
            <strong>Intellectual Property:</strong> Users must respect the intellectual property rights of others. This includes not sharing copyrighted materials without permission, not plagiarizing the work of others, and giving proper credit when using or referencing someone else's work.
            </p>
            <br/>
            <p>
            <strong>Privacy and Personal Information:</strong> Users should be mindful of their privacy and the privacy of others. Sharing personal information such as full names, addresses, phone numbers, or any other sensitive information without consent is prohibited. Users should also be cautious not to share login credentials or allow unauthorized users to access their accounts.
            </p>
            <br/>
            <p>
            <strong>Compliance with Laws:</strong> Users must comply with all local, state, federal, and international laws while using our services. This includes laws pertaining to copyright, data privacy, and online conduct.
            </p>
            <br/>
            <p>
            <strong>Moderation:</strong> Kids Learn Coding LLC employs various moderation techniques to monitor content and interactions within our platform to ensure they adhere to our User Conduct and Safety guidelines. This may include automated systems and human moderators.
            </p>
            <br/>
            <p>
            <strong>Reporting Mechanisms:</strong> Users have the ability to report any behavior or content that violates our conduct guidelines. We encourage our community to report any concerns promptly so that our team can take appropriate action.
            </p>
            <br/>
            <p>
            <strong>Account Suspension and Termination:</strong> Users found to be in violation of these conduct and safety guidelines may have their accounts suspended or terminated, at the discretion of Kids Learn Coding LLC. Severe violations may lead to immediate termination without prior warning.
            </p>
            <br/>
            <p>
            <strong>Education and Awareness:</strong> We believe in educating our users about online safety and digital citizenship. Resources and guidelines will be provided to help users understand how to interact safely and respectfully online.
            </p>
            <br/>
            <p>
            By using the services provided by Kids Learn Coding LLC, users agree to abide by these User Conduct and Safety guidelines. It is the responsibility of all users, including parents and guardians of child users, to ensure that their use of our platform remains in accordance with these guidelines to maintain a safe and positive learning environment for everyone.
            </p>


            <h1 id="lessonScheduling" >
            8. Lesson Scheduling and Rescheduling
            </h1>

            <p>
            <strong>Agreement to Reschedule Policy:</strong> By scheduling lessons with Kids Learn Coding LLC, users explicitly agree to our reschedule policy that can be found by <a className='link' onClick={()=>navigate("/reschedule-policy")} >clicking this link</a>. We highly recommend reading the full reschedule policy for comprehensive details. Our goal is to provide a supportive and flexible educational environment. Adhering to these policies ensures that we can continue to offer high-quality coding education to all our students.
            </p>
            <br/>
            <p>
            By scheduling lessons with Kids Learn Coding LLC, users agree to abide by these Lesson Scheduling and Rescheduling policies. Our goal is to provide a supportive and flexible educational environment. Adhering to these policies ensures that we can continue to offer high-quality coding education to all our students.
            </p>


            <h1 id="refundPolicy" >
            9. Refund Policy
            </h1>
            <p>
            At Kids Learn Coding LLC, accessible via kidslearncoding.org, we are committed to providing a high-quality learning experience for all our students. We understand that circumstances may arise where a refund request may be considered. This section outlines our refund policy, including conditions under which refunds may or may not be granted, to ensure transparency and understanding between Kids Learn Coding LLC and our users.
            </p>
            <br/>
            <p>
            <strong>Non-refundable Fees:</strong> All subscription fees, once charged, are non-refundable. This policy applies regardless of the user’s level of participation in scheduled classes during the billing period.
            </p>
            <br/>
            <p>
            <strong>Free Trial Period:</strong> Users who subscribe to a free trial of our services and subsequently transition into a paid subscription must cancel their subscription before the end of the trial period if they do not wish to continue into the paid subscription. Users who do not cancel before the trial period ends and are automatically enrolled in the paid subscription will not be eligible for a refund.
            </p>
            <br/>
            <p>
            <strong>Cancellation of Subscription:</strong> Users can cancel their subscription at any time. Cancellation will prevent further charges at the next billing cycle, but users will not receive a refund for the current billing period. Access to subscription services will continue until the end of the current billing cycle.
            </p>
            <br/>
            <p>
            <strong>Requesting a Refund:</strong> To request a refund, users must contact our customer service team via the contact information provided on our website. Users should include their account information and a detailed explanation of the reason for the refund request.
            </p>
            <br/>
            <p>
            <strong>Review Process:</strong> Each refund request will be reviewed on a case-by-case basis. Our customer service team will consider the circumstances of the request, the user’s account history, and any relevant details before making a decision.
            </p>
            <br/>
            <p>
            Kids Learn Coding LLC reserves the right to modify this refund policy at any time. Any changes will be communicated to users through our website and will be effective immediately upon posting. Continued use of our services after changes to the refund policy have been made constitutes acceptance of those changes.
            </p>


            <h1 id="thirdPartyServices" >
            10.	Third-Party Services and Platforms
            </h1>
            <p>
            At Kids Learn Coding LLC, accessible via kidslearncoding.org, we utilize a variety of third-party services and platforms to enhance the educational experience we offer. These may include, but are not limited to, video conferencing tools (e.g., Zoom) for live classes, educational software (e.g., Scratch, Roblox Studio, Visual Studio Code) for coding projects, and payment processing services for subscription management. This section outlines our policies and guidelines regarding the use of third-party services and platforms.
            </p>
            <br/>
            <p>
            <strong>Purpose of Integration:</strong> The integration of third-party services and platforms is intended to provide a comprehensive, interactive, and efficient learning experience. These tools facilitate live instruction, enable project-based learning, and ensure secure payment transactions.
            </p>
            <br/>
            <p>
            <strong>User Registration and Accounts:</strong> Certain third-party platforms may require users to create an account or provide personal information directly to the service provider. We advise users to review the terms of service and privacy policies of these third-party platforms before registering or submitting any personal information.
            </p>
            <br/>
            <p>
            <strong>Compliance with Third-Party Terms:</strong> Users are responsible for complying with the terms of service, privacy policies, and usage guidelines of any third-party platforms used in conjunction with our services. This includes adhering to acceptable use policies and respecting copyright and intellectual property rights.
            </p>
            <br/>
            <p>
            <strong>Security and Privacy:</strong> While we select third-party services that prioritize security and privacy, we cannot control or guarantee the security of information users choose to share with third-party platforms. Users should exercise caution and protect their personal information when using these services.
            </p>
            <br/>
            <p>
            <strong>Selection and Integration:</strong> Kids Learn Coding LLC selects third-party services and platforms based on their utility, reliability, and compatibility with our educational objectives. We strive to integrate these tools seamlessly into our services to enhance the learning experience.
            </p>
            <br/>
            <p>
            <strong>No Endorsement:</strong> The use of third-party services and platforms does not constitute an endorsement of these services by Kids Learn Coding LLC. We are not responsible for the content, policies, or practices of third-party websites or platforms.
            </p>
            <br/>
            <p>
            <strong>Technical Support:</strong> Kids Learn Coding LLC will provide basic guidance on how to access and use third-party platforms as part of our services. However, for platform-specific issues or technical support, users may need to contact the third-party service provider directly.
            </p>
            <br/>
            <p>
            Kids Learn Coding LLC is not liable for any damages, claims, or losses incurred as a result of a user's access to, use of, or reliance on third-party services and platforms. Users assume full responsibility for their use of these third-party tools and for ensuring that their use complies with all relevant terms and conditions.
            </p>
            <br/>
            <p>
            The third-party services and platforms we use may change over time as we seek to improve our services. We will notify users of any significant changes to the third-party services that are integral to our courses and provide updated guidance on their use.
            </p>
            <br/>
            <p>
            By using Kids Learn Coding LLC’s services, users acknowledge and agree to the terms outlined in this section regarding the use of third-party services and platforms. Our goal is to provide a safe, effective, and engaging learning environment through the careful selection and integration of these tools.
            </p>


            <h1 id="recordedSessions" >
            11.	Recorded Sessions
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, the safety and security of our students and teachers are of paramount importance. To this end, we implement the practice of recording all live coding sessions conducted through third-party video conferencing platforms (e.g., Zoom). The purpose of these recordings is to ensure a safe learning environment for everyone involved. This section outlines our policies regarding the recording of sessions, use of these recordings, and the consent requirement.</p>
            <br/>
            <p><strong>Purpose of Recordings:</strong> Recordings are made to enhance safety measures, providing a secure and protective environment for both students and teachers. The recordings help in monitoring compliance with our conduct policies and serve as a safeguard against inappropriate behavior.</p>
            <br/>
            <p><strong>Consent Requirement:</strong> Participation in our live coding sessions requires consent to be recorded. By agreeing to participate in these sessions, users consent to the recording of all interactions during the session. We do not offer the option to participate in live sessions without being recorded, as this practice is a cornerstone of our safety protocols.</p>
            <br/>
            <p><strong>Access Restrictions:</strong> Recorded sessions are strictly used for internal review by Kids Learn Coding LLC’s authorized staff and are not made available to students, parents, or any external parties. The primary use of these recordings is for reviewing incidents, if any, and ensuring adherence to our safety and conduct standards.</p>
            <br/>
            <p><strong>Storage and Security:</strong> All recorded sessions are stored securely, with access limited to staff members who have undergone strict vetting processes. Recordings are protected with encryption and other security measures to prevent unauthorized access.</p>
            <br/>
            <p><strong>Retention Policy:</strong> Recorded sessions are kept for a limited duration, sufficient to address any potential issues that may arise. Following this period, recordings are securely deleted in accordance with our data protection policies.</p>
            <br/>
            <p><strong>Handling of Personal Information:</strong> During recorded sessions, participants are advised to avoid sharing personal or sensitive information. Kids Learn Coding LLC takes privacy seriously and recordings are managed in a way that respects the privacy of all participants.</p>
            <br/>
            <p><strong>Opt-Out Policy:</strong> Due to the integral role of session recordings in our safety and security measures, opting out of recordings is not available. Participation in our services implies agreement to this recording policy as a condition of use.</p>
            <br/>
            <p><strong>Compliance with Laws:</strong> Our recording practices are conducted in strict compliance with applicable privacy laws and regulations. We are committed to upholding the highest standards of legal and ethical conduct in all our operations.</p>
            <br/>
            <p><strong>Review and Oversight:</strong> Kids Learn Coding LLC maintains the right to review recorded sessions as part of our commitment to safety and quality assurance. These reviews are conducted with the utmost respect for privacy and confidentiality.</p>
            <br/>
            <p>By utilizing the services provided by Kids Learn Coding LLC, users acknowledge and agree to the mandatory recording of live sessions as outlined above. This policy is enforced to ensure the safety and well-being of our student and teacher community, supporting our mission to provide a secure and conducive learning environment for all.</p>

            
            <h1 id="contentOwnership" >
            12.	Content Ownership and Use Rights
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, we foster an environment where creativity and learning go hand in hand. This involves the creation, sharing, and utilization of various content forms by students, teachers, and the organization itself. This section details the ownership of content produced within our platform and the rights users have regarding this content.</p>
            <br/>
            <p><strong>Kids Learn Coding LLC Content:</strong> All educational materials, including but not limited to course outlines, lesson plans, videos, tutorials, and exercises created and provided by Kids Learn Coding LLC, are the exclusive property of Kids Learn Coding LLC. These materials are offered to users (students, parents, and guardians) for personal, non-commercial educational use only.</p>
            <br/>
            <p><strong>User-Generated Content:</strong> Content created by users, including code written by students as part of their learning exercises, projects completed within the platform, and any interaction within community forums, remains the intellectual property of the individual creator. However, by participating in the platform, users grant Kids Learn Coding LLC a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to use, copy, modify, distribute, publish, and process information and content provided by users, without any further consent, notice and/or compensation to them or others.</p>
            <br/>
            <p><strong>Personal Use:</strong> Users are granted a limited, non-exclusive right to access and use Kids Learn Coding LLC content for personal educational purposes. This right does not extend to commercial use, distribution, public display, or any form of reproduction beyond personal use or as explicitly authorized by Kids Learn Coding LLC.</p>
            <br/>
            <p><strong>Sharing and Redistribution:</strong> Redistribution of Kids Learn Coding LLC materials outside the confines of the classroom or the platform, including online forums, other educational institutions, or any other third-party platforms, is strictly prohibited unless expressly permitted by Kids Learn Coding LLC in writing.</p>
            <br/>
            <p><strong>Modification of Content:</strong> Users may not modify, adapt, or create derivative works from Kids Learn Coding LLC content without obtaining prior written permission from Kids Learn Coding LLC. User-generated content created as part of course assignments or projects may be shared within the Kids Learn Coding LLC community for educational and promotional purposes, with proper attribution to the original creator.</p>
            <br/>
            <p><strong>Respect for Copyright Laws:</strong> Kids Learn Coding LLC respects the intellectual property rights of others and expects its users to do the same. Any use of third-party copyrighted materials within user-generated content must comply with fair use laws and include appropriate attribution.</p>
            <br/>
            <p><strong>Notification of Infringement:</strong> If you believe that your copyright or intellectual property rights have been infringed upon by content on our platform, please notify us promptly. Kids Learn Coding LLC commits to investigating such claims and taking appropriate action, including removing infringing content from the platform.</p>
            <br/>
            <p>Kids Learn Coding LLC reserves the right to terminate or revoke the use rights granted to users at any time if they are found to be in violation of these terms. Upon termination, the user must cease all use of Kids Learn Coding LLC content and delete or return any copies of materials obtained from the platform.</p>


            <h1 id="intellectualProperty" >
            13.	Intellectual Property Rights in Student Creations
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, we celebrate the creativity and innovation that our students display through their coding projects and assignments. This section delineates the intellectual property rights concerning the creations and works produced by students while participating in our courses and using our platform.</p>
            <br/>
            <p><strong>Ownership:</strong> Students retain ownership over all original creations, including software, applications, digital works, and projects ("Student Creations") they produce as part of their coursework at Kids Learn Coding LLC. These creations are the intellectual property of the individual student, and any use of such creations by Kids Learn Coding LLC or third parties will respect the student's ownership rights.</p>
            <br/>
            <p><strong>Grant of License:</strong> By participating in our courses and using our platform, students grant Kids Learn Coding LLC a non-exclusive, royalty-free, worldwide license to use, display, and reproduce the Student Creations within the confines of the platform for educational and promotional purposes, including showcasing examples of student work, without further consent or compensation. This license is intended to enable Kids Learn Coding LLC to enhance the educational experience for all students and to promote the successes of our student community.</p>
            <br/>
            <p><strong>Student Sharing:</strong> Students are encouraged to share their creations within the Kids Learn Coding LLC community and beyond, for educational, non-commercial purposes. Students may also choose to publish or distribute their creations independently, provided they comply with the terms of service of any third-party platforms used for such purposes.</p>
            <br/>
            <p><strong>Acknowledgment:</strong> When Student Creations are used by Kids Learn Coding LLC for educational or promotional purposes, the student will be acknowledged as the creator, respecting their contribution to the community and their rights as the intellectual property owner.</p>
            <br/>
            <p><strong>Compliance with Copyright Laws:</strong> Students are responsible for ensuring that their creations do not infringe upon the copyright or other intellectual property rights of third parties. This includes obtaining necessary permissions and making appropriate attributions when using third-party content within their projects.</p>
            <br/>
            <p><strong>Notification of Infringement:</strong> If a student believes that their intellectual property rights have been infringed upon by another user or content on the platform, they should notify Kids Learn Coding LLC immediately. We are committed to protecting the intellectual property rights of our users and will take appropriate action to address any infringement claims, including removing infringing content from the platform.</p>
            <br/>
            <p><strong>Revocation:</strong> The license granted to Kids Learn Coding LLC regarding the use of Student Creations may be terminated by the student at any time, with the understanding that certain uses, such as inclusion in historical showcases or compilation works, may continue where the student's creation has been integrated prior to the revocation of the license.</p>


            <h1 id="technologyRequirements" >
            14.	Technology Requirements
            </h1>
            <p>To ensure a seamless and productive learning experience at Kids Learn Coding LLC, accessible via kidslearncoding.org, it is essential for all participants to meet certain technology requirements. This section outlines the necessary hardware, software, and internet connectivity standards required for students to effectively participate in our online coding courses and utilize our platform.</p>
            <br/>
            <p><strong>Computer:</strong> A desktop or laptop computer capable of running the latest web browsers. Tablets or smartphones may be used for some activities, but a computer is strongly recommended for the best learning experience.</p>
            <br/>
            <p><strong>Webcam and Microphone:</strong> For participating in live coding sessions, a working webcam and microphone are required to facilitate interactive learning and communication with instructors and peers.</p>
            <br/>
            <p><strong>Speakers or Headphones:</strong> To ensure clear audio during live sessions and while viewing recorded content, speakers or headphones are needed.</p>
            <br/>
            <p><strong>Web Browsers:</strong> The latest version of Google Chrome, Mozilla Firefox, Safari, or Microsoft Edge is required for accessing the Kids Learn Coding LLC platform and course materials.</p>
            <br/>
            <p><strong>Coding Software:</strong> Depending on the course, specific coding environments or software such as Scratch, Roblox Studio, or Visual Studio Code may be required. Links to free downloads and installation instructions will be provided.</p>
            <br/>
            <p><strong>Video Conferencing Tool:</strong> Live sessions are conducted via a third-party video conferencing tool (e.g., Zoom). Students will need to download and install the application or ensure web-based access is possible on their device.</p>
            <br/>
            <p><strong>Stable Internet Connection:</strong> A reliable and stable internet connection is crucial for accessing course materials, participating in live sessions, and submitting assignments. A minimum broadband connection of 5 Mbps download and 2 Mbps upload speed is recommended.</p>
            <br/>
            <p><strong>Operating System:</strong> While most course materials and platforms are accessible across various operating systems, certain software requirements may be specific to Windows or macOS. Ensure compatibility with course-specific software.</p>
            <br/>
            <p><strong>Storage Space:</strong> Adequate storage space on the device for software installation and project development is recommended.</p>
            <br/>
            <p><strong>Security:</strong> Updated antivirus and malware protection to safeguard students' work and personal information.</p>
            <br/>
            <p>By enrolling in courses offered by Kids Learn Coding LLC, students, parents, or guardians acknowledge these technology requirements and confirm that the student has access to the necessary technology to participate effectively in the learning activities.</p>



            <h1 id="codeOfConduct" >
            15.	Code of Conduct
            </h1>
            <p>
            At Kids Learn Coding LLC, accessible via kidslearncoding.org, we are dedicated to creating an inclusive, respectful, and safe learning environment for all our students, instructors, and staff. This Code of Conduct outlines the expectations for behavior within our community and during our online sessions, ensuring a positive and productive experience for everyone involved.
            </p>
            <br/>
            <p>
              <strong>General Conduct</strong>
            </p>
            <br/>
            
            <p><strong>Respect:</strong> Treat all individuals with respect, kindness, and dignity. Harassment, bullying, discriminatory remarks, or any form of intimidation will not be tolerated.</p>
            <br/>
            <p><strong>Integrity:</strong> Uphold the highest standards of academic integrity. Plagiarism, cheating, or any dishonest behavior is strictly prohibited.</p>
            <br/>
            <p><strong>Collaboration:</strong> While collaboration is encouraged, always give credit where it is due. Do not represent someone else's work as your own.</p>
            <br/>
            <p><strong>Online Sessions</strong></p>
            <br/>
            <p><strong>Participation:</strong> Actively participate in online sessions in a manner that is constructive and respectful to the learning process.</p>
            <br/>
            <p><strong>Punctuality:</strong> Arrive on time for live sessions and inform the instructor in advance if you are unable to attend or will be late.</p>
            <br/>
            <p><strong>Appropriate Environment:</strong> Ensure your learning environment is free from distractions and conducive to learning. This includes having a quiet space and using a suitable background when on video.</p>
            <br/>
            <p><strong>Communication</strong></p>
            <br/>
            <p><strong>Professionalism</strong>: All communications, whether verbal or written, should be conducted professionally and respectfully.</p>
            <br/>
            <p><strong>Privacy:</strong> Respect the privacy of others. Do not share personal information about other students or instructors without their explicit consent.</p>
            <br/>
            <p><strong>Feedback:</strong> Provide constructive feedback. When offering criticism, do so respectfully with the aim of supporting and improving the learning experience.</p>
            <br/>
            <p><strong>Use of Technology</strong></p>
            <br/>
            <p><strong>Responsible Use:</strong> Utilize the platform and any provided software tools responsibly and solely for the purpose of learning and completing coursework.</p>
            <br/>
            <p><strong>Security:</strong> Do not attempt to harm, hack, or otherwise compromise the security of the learning platform or any related technology.</p>
            <br/>
            <p><strong>Content Sharing:</strong> Share only appropriate and relevant content. Posting or sharing harmful, offensive, or inappropriate material is strictly forbidden.</p>
            <br/>
            <p><strong>Enforcement</strong></p>
            <br/>
            <p><strong>Reporting:</strong> Any violations of the Code of Conduct should be reported to Kids Learn Coding LLC staff immediately. Reports can be made anonymously and will be treated with confidentiality.</p>
            <br/>
            <p><strong>Consequences:</strong> Violations of this Code of Conduct may result in disciplinary action, including but not limited to warnings, suspension from live sessions, or termination of enrollment without a refund.</p>
            <br/>
            <p>By enrolling in courses and participating in the community at Kids Learn Coding LLC, students, parents, and guardians commit to adhering to this Code of Conduct. Our collective adherence to these guidelines ensures that Kids Learn Coding LLC remains a positive, productive, and welcoming learning environment for everyone.</p>






            <h1 id="linksToThirdParty" >
            16.	Links to Third-Party Sites
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, we strive to provide a comprehensive and enriching learning experience for our students. As part of our educational content, we may provide links to third-party websites that offer additional resources, tools, or information relevant to our coding courses. This section outlines our policy and guidance regarding the use of links to third-party sites.</p>
            <br/>
            <p><strong>Educational Enhancement:</strong> Links to third-party sites are provided as a convenience to enhance the educational experience. These may include references to coding software, educational articles, tutorials, or other resources that supplement our curriculum.</p>
            <br/>
            <p><strong>Curated Selection:</strong> Our team carefully curates these links, selecting resources that align with our educational goals and standards. However, the inclusion of a link does not imply endorsement of the third-party site's content, products, or services.</p>
            <br/>
            <p><strong>Independent Evaluation:</strong> Users are encouraged to independently evaluate the content and safety of third-party sites. Kids Learn Coding LLC does not control, and is not responsible for, the content found on external sites. The terms of use, privacy policies, and content of these sites are not governed by our policies.</p>
            <br/>
            <p><strong>Safety and Security:</strong> While we aim to link only to reputable and safe resources, the dynamic nature of the internet means that web content can change rapidly. Users are advised to use caution, particularly when downloading software or providing personal information to third-party sites.</p>
            <br/>
            <p><strong>No Liability:</strong> Kids Learn Coding LLC disclaims all liability for any damages, direct or indirect, resulting from the use of or reliance on third-party websites. Users assume full responsibility for their use of any external sites.</p>
            <br/>
            <p><strong>Reporting Concerns:</strong> If users encounter problematic content or issues with sites we link to, we encourage them to report these concerns to our support team. While we cannot rectify external site issues, we can review and potentially remove the link from our resources.</p>
            <br/>
            <p><strong>Dynamic Resource List:</strong> The list of third-party resources and links we provide may change over time as we update our courses and find new or better resources. We recommend checking back periodically for updated links and resources.</p>
            <br/>
            <p><strong>Feedback and Suggestions:</strong> We welcome feedback and suggestions from our community regarding additional resources or concerns about existing third-party links. Our goal is to ensure that all linked content adds value and is in line with our commitment to providing a safe and effective learning environment.</p>


            <h1 id="disclaimerOfWarranties" >
            17.	Disclaimer of Warranties and Accuracy of Materials
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, we are dedicated to providing high-quality, engaging, and educational coding content to our students. While we strive for excellence and accuracy in all our educational materials and platform functionalities, this section outlines the limitations of warranties regarding the content provided on our website and through our services.</p>
            <br/>
            <p><strong>As-Is Basis:</strong> All content, resources, and services provided by Kids Learn Coding LLC are offered on an “as-is” and “as available” basis, without warranties of any kind, either express or implied. This includes, but is not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement of intellectual property rights.</p>
            <br/>
            <p><strong>Accuracy of Materials:</strong> We endeavor to ensure that all materials provided as part of our courses are accurate and up to date. However, Kids Learn Coding LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>
            <br/>
            <p><strong>Technical Errors and Omissions:</strong> The content provided by Kids Learn Coding LLC may contain technical, typographical, or photographic errors. We do not guarantee that any of the materials on our website are accurate, complete, or current. We may make changes to the materials contained on our website at any time without notice, but we make no commitment to update the materials.</p>
            <br/>
            <p><strong>External Links:</strong> Our website and courses may include links to external websites or resources. Kids Learn Coding LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Kids Learn Coding LLC of the site. Use of any such linked website is at the user’s own risk.</p>
            <br/>
            <p><strong>Verification:</strong> Users are encouraged to verify the accuracy and completeness of all materials before relying on them. Users assume the total responsibility and risk for their use of the website and the internet.</p>
            <br/>
            <p><strong>Updates and Corrections:</strong> While Kids Learn Coding LLC invites feedback to improve the accuracy of its materials, it is ultimately the user's responsibility to ensure that the information they rely on is up to date and correct.</p>
            <br/>
            <p><strong>Compliance with Laws:</strong> Users agree to comply with all applicable laws and regulations in their use of Kids Learn Coding LLC’s materials and services. This includes adherence to copyright and intellectual property laws concerning the use of content and third-party resources.</p>



            <h1 id="limitationOfLiability" >
            18. Limitation of Liability
            </h1>
            <p>In providing an educational platform at Kids Learn Coding LLC, accessible via kidslearncoding.org, we are committed to fostering a supportive and enriching learning environment for our students. However, it is important to understand the boundaries of our responsibilities and liabilities. This section outlines the limitation of liability of Kids Learn Coding LLC regarding the use of our website, services, and content.</p>
            <br/>
            <p><strong>General Limitation:</strong> To the fullest extent permitted by law, Kids Learn Coding LLC, including its directors, employees, partners, agents, suppliers, or affiliates, shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the services; (ii) any conduct or content of any third party on the services; (iii) any content obtained from the services; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
            <br/>
            <p><strong>Content and Services:</strong> While Kids Learn Coding LLC strives to provide high-quality content and uninterrupted service, we do not guarantee that the content will always be accurate, complete, or up-to-date, or that the service will be uninterrupted, timely, secure, or error-free. The use of information obtained through the platform is at your own discretion and risk.</p>
            <br/>
            <p><strong>Third-Party Links:</strong> Kids Learn Coding LLC is not liable for any damages or losses caused by any content, goods, or services available on or through any linked sites or resources. Users are advised to read the terms and conditions and privacy policies of any third-party websites or services that they visit.</p>
            <br/>
            <p><strong>User Acknowledgment:</strong> By using Kids Learn Coding LLC’s services, you acknowledge and agree that the exclusions and limitations of liability set out in this section are reasonable. If you do not think they are reasonable, you must not use this service.</p>
            <br/>
            <p><strong>Indemnification:</strong> Users agree to indemnify and hold harmless Kids Learn Coding LLC, its contractors, and its licensors, and their respective directors, officers, employees, and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the website, including but not limited to your violation of this Agreement.</p>
            <br/>
            <p><strong>Jurisdictional Considerations:</strong> Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations of liability may not apply to you. In such cases, Kids Learn Coding LLC’s liability will be limited to the greatest extent permitted by law.</p>


            <h1 id="disputeResolution" >
            19.	Dispute Resolution
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, we aim to provide a positive and enriching experience for all our users. We understand, however, that disputes may occasionally arise. To address such situations effectively and to ensure a fair resolution, we have established the following dispute resolution process.</p>
            <br/>
            <p><strong>Informal Resolution</strong></p>
            <br/>
            <p><strong>Initial Contact:</strong> Before pursuing any formal dispute resolution proceedings, we kindly ask that users contact us directly to seek an informal resolution. Many disputes can be resolved quickly and amicably by discussing the issue with our customer support team. Users can reach us via the contact information provided on our website.</p>
            <br/>
            <p><strong>Good Faith Negotiation:</strong> Both parties agree to engage in good faith negotiations to attempt to resolve any dispute, claim, question, or disagreement directly through consultation and good faith negotiations, which shall be a precondition to either party initiating a lawsuit or arbitration.</p>
            <br/>
            <p><strong>Formal Dispute Resolution</strong></p>
            <br/>
            <p><strong>Mediation:</strong> If the dispute cannot be resolved through informal negotiations within a reasonable time frame, both parties agree to participate in mediation before a neutral mediator. The goal of mediation will be to reach a mutually satisfactory resolution. Costs and fees for mediation will be shared equally by both parties.</p>
            <br/>
            <p><strong>Arbitration:</strong> In the event that mediation does not resolve the dispute, both parties agree that the dispute shall be referred to and finally resolved by binding arbitration under the rules of the American Arbitration Association (AAA) or another recognized arbitration institution, as mutually agreed upon by the parties. The arbitration shall take place in the jurisdiction where Kids Learn Coding LLC is headquartered, unless otherwise agreed.</p>
            <br/>
            <p><strong>Arbitration Agreement:</strong> By agreeing to these Terms of Service, users agree that, with the exception of disputes relating to the infringement of Kids Learn Coding LLC’s intellectual property rights or the rights of Kids Learn Coding LLC’s licensors, any dispute, claim, or controversy arising out of or in connection with these Terms or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by arbitration.</p>
            <br/>
            <p><strong>Waiver of Jury Trial:</strong> The parties hereby waive their constitutional and statutory rights to go to court and have a trial in front of a judge or a jury, choosing instead to resolve any disputes through arbitration.</p>
            <br/>
            <p><strong>Governing Law</strong></p>
            <br/>
            <p>These Terms of Service and any disputes arising out of or related to these Terms of Service or the use of Kids Learn Coding LLC’s services shall be governed by and construed in accordance with the laws of the jurisdiction in which Kids Learn Coding LLC is headquartered, without regard to its conflict of law principles.</p>
            <br/>
            <p><strong>Limitations on Claims</strong></p>
            <br/>
            <p>Any cause of action or claim you may have arising out of or related to these terms of service or Kids Learn Coding LLC’s services must be commenced within one (1) year after the cause of action accrues; otherwise, such cause of action or claim is permanently barred.</p>

            <h1 id="terminationOfUse" >
            20.	Termination of Use
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, we value our relationship with our community of learners, educators, and supporters. However, under certain circumstances, it may become necessary to terminate a user's access to our services. This section outlines the conditions under which the use of Kids Learn Coding LLC’s services may be terminated and the process for such termination.</p>
            <br/>
            <p><strong>Conditions for Termination</strong></p>
            <br/>
            <p><strong>Violation of Terms:</strong> Use of Kids Learn Coding LLC’s services may be terminated as a result of any breach or violation of the Terms of Service, including, but not limited to, non-compliance with the Code of Conduct, misuse of the services, or infringement of intellectual property rights.</p>
            <br/>
            <p><strong>Misuse of Services:</strong> Engaging in illegal activities, attempting to disrupt the services or the platform's operation, or misuse of Kids Learn Coding LLC’s resources, including unauthorized use of the system to access, alter, delete, damage, or disrupt the information of Kids Learn Coding LLC or other users, will result in immediate termination of use.</p>
            <br/>
            <p><strong>Non-Payment:</strong> Subscription-based services may be terminated due to non-payment. Users will receive notification and an opportunity to rectify the situation before termination occurs.</p>
            <br/>
            <p><strong>Process for Termination</strong></p>
            <br/>
            <p><strong>Notification:</strong> In the event that Kids Learn Coding LLC determines that a user’s access to the services will be terminated, the user will be notified via email or through a message on the platform, providing the reason for termination and the effective date.</p>
            <br/>
            <p><strong>Opportunity to Respond:</strong> Users will have the opportunity to respond to the notification, providing any relevant information or argument against the termination decision. Kids Learn Coding LLC will review this response before making a final decision.</p>
            <br/>
            <p><strong>Effect of Termination:</strong> Upon termination, the user will lose access to their account, courses, and all services provided by Kids Learn Coding LLC. Any content or data stored on the platform may be deleted, and Kids Learn Coding LLC is not responsible for the loss of such content.</p>
            <br/>
            <p><strong>Reinstatement:</strong> In certain cases, at the discretion of Kids Learn Coding LLC, a user may be allowed to apply for reinstatement of their services. The process for reinstatement will require a review of the user’s conduct and assurances of future compliance with the Terms of Service.</p>
            <br/>
            <p><strong>User-Initiated Termination</strong></p>
            <br/>
            <p>Users may choose to terminate their use of Kids Learn Coding LLC’s services at any time by notifying Kids Learn Coding LLC through the contact information provided on the website. Upon receipt of such a request, Kids Learn Coding LLC will proceed with the account closure and inform the user once the process is complete.</p>
            <br/>
            <p><strong>Data Retention and Deletion</strong></p>
            <br/>
            <p>Upon termination, Kids Learn Coding LLC will retain and delete user data in accordance with our Privacy Policy and applicable laws and regulations. Users may request the deletion of their data, subject to legal and operational requirements that may require the retention of data for a specified period.</p>




            <h1 id="feedbackAndCopyright" >
            21. Feedback and Copyright Complaints
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, we are committed to fostering a collaborative and respectful learning environment. We value the feedback from our community and take copyright concerns seriously. This section outlines the process for providing feedback and submitting copyright complaints.</p>
            <br/>
            <p><strong>Providing Feedback</strong></p>
            <br/>
            <p><strong>Encouragement of Feedback:</strong> We encourage students, parents, educators, and any users of our services to share their feedback, suggestions, and concerns regarding our courses, platform, and overall service. Feedback is invaluable to us for improving the quality and effectiveness of our educational offerings.</p>
            <br/>
            <p><strong>How to Provide Feedback:</strong> Feedback can be provided through the contact form available on our website, via email, or through any designated feedback channels we may offer. We strive to review all feedback promptly and may follow up for further information or clarification if necessary.</p>
            <br/>
            <p><strong>Use of Feedback:</strong> Feedback provided to Kids Learn Coding LLC may be used to make decisions on content updates, feature enhancements, and service improvements. We may also share generalized feedback publicly to highlight the impact of our services and potential areas for further development.</p>
            <br/>
            <p><strong>Copyright Complaints</strong></p>
            <br/>
            <p><strong>Respect for Intellectual Property:</strong> Kids Learn Coding LLC respects the intellectual property rights of others and expects its users to do the same. We comply with the provisions of copyright law and require that the content uploaded or shared by users on our platform does not infringe upon the copyright of any third party.</p>
            <br/>
            <p><strong>Submitting a Copyright Complaint:</strong> If you believe that your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please notify us. Your notice should include the following information:</p>
            <br/>
            <ul>
              <li>A description of the copyrighted work or intellectual property that you claim has been infringed;</li>
              <li>A description of where the material you claim is infringing is located on the site (providing URLs in the body of an email is the best way to help us locate content quickly);</li>
              <li>Your address, telephone number, and email address;</li>
              <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
              <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
            </ul>
            <br/>
            <p><strong>Process Following a Complaint:</strong> Upon receiving a copyright complaint, Kids Learn Coding LLC will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the website.</p>
            <br/>
            <p><strong>Counter-Notice:</strong> If you believe that your content was removed in error, you may send us a counter-notice. The counter-notice should include proof that the content was removed by mistake or misidentification and consent for the jurisdiction of the Federal Court for the judicial district in which the address is located, or if outside the U.S., for any judicial district in which Kids Learn Coding LLC may be found.</p>
            <br/>
            <p><strong>Resolution Process</strong></p>
            <br/>
            <p><strong>Review and Action:</strong> All feedback and copyright complaints will be reviewed by our team. Appropriate actions, including but not limited to content modification, removal, or platform improvement, will be taken based on the nature of the feedback or complaint.</p>
            <br/>
            <p><strong>Communication:</strong> We are committed to communicating openly and transparently with our users. You will be informed of the receipt of your feedback or complaint and, where applicable, the actions taken in response.</p>



            <h1 id="governingLaw" >
            22. Governing Law
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, we operate our services with the intention of providing quality online coding education to a diverse audience. The services we offer are designed to be accessible and beneficial to users worldwide. However, it is important to recognize that our operations are primarily governed by the laws and regulations of the jurisdiction in which we are established. This section outlines the governing law that applies to the Terms of Service and any disputes arising under them.</p>
            <br/>
            <p><strong>Jurisdiction</strong></p>
            <br/>
            <p><strong>Primary Governance:</strong> The Terms of Service, including any matters relating to the use of the Kids Learn Coding LLC website and services, shall be governed by and construed in accordance with the laws of the [Jurisdiction], without giving effect to any principles of conflicts of law.</p>
            <br/>
            <p><strong>Legal Compliance:</strong> Users of Kids Learn Coding LLC agree to comply with all local laws and regulations applicable to them concerning the use of Kids Learn Coding LLC’s services. This includes adherence to laws relating to online conduct, acceptable content, and the transmission of technical data exported from the country in which the user resides.</p>
            <br/>
            <p><strong>Dispute Resolution</strong></p>
            <br/>
            <p><strong>Jurisdiction for Disputes:</strong> Any disputes arising out of or related to the Terms of Service or the use of Kids Learn Coding LLC’s services shall be subject to the exclusive jurisdiction of the courts located within the [Jurisdiction]. Users consent to the personal jurisdiction and venue in these courts.</p>
            <br/>
            <p><strong>International Use Considerations</strong></p>
            <br/>
            <p><strong>International Users:</strong> While Kids Learn Coding LLC is based in [Jurisdiction], it is accessible to international users. We make no representation that our website or services are appropriate or available for use in locations outside of [Jurisdiction]. Users who access or use the services from other jurisdictions do so at their own volition and are responsible for compliance with local law.</p>
            <br/>
            <p><strong>Modifications to Governing Law Clause</strong></p>
            <br/>
            <p><strong>Changes to This Section:</strong> Kids Learn Coding LLC reserves the right to modify the Governing Law section of the Terms of Service as required to reflect changes in applicable law or in our business practices. Users will be notified of any significant changes and continued use of the services will constitute acceptance of the new terms.</p>

            
            <h1 id="ageRequirements" >
            23. Age Requirements
            </h1>
            <p>At Kids Learn Coding LLC, accessible via kidslearncoding.org, our mission is to make coding education accessible and engaging for children of all ages. We recognize the importance of ensuring a safe and age-appropriate learning environment, especially for our younger users. As such, we have established specific guidelines regarding age requirements and parental consent for using our services. This section outlines these guidelines.</p>
            <br/>
            <p><strong>General Age Guidelines</strong></p>
            <br/>
            <p><strong>Open to All Ages:</strong> Our platform and services are designed to be accessible to learners of all ages, with content tailored to different skill levels and age groups. While there is no minimum age for accessing the educational content provided by Kids Learn Coding LLC, certain aspects of our service, such as account creation and participation in interactive elements, are subject to parental consent requirements for children under the age of 13.</p>
            <br/>
            <p><strong>Parental Consent Requirements</strong></p>
            <br/>
            <p><strong>Parental Consent for Children Under 13:</strong> In accordance with the Children's Online Privacy Protection Act (COPPA) and to ensure the safety and privacy of children under the age of 13, Kids Learn Coding LLC requires parental consent for such children to create an account and engage with certain interactive features of our platform.</p>
            <br/>
            <p><strong>Lack of Age Verification:</strong> It is important to note that Kids Learn Coding LLC does not implement direct age verification processes. Instead, we rely on parents and guardians to provide consent and supervise their children's use of our services. This approach places the responsibility on parents and guardians to ensure their children's compliance with our age requirements and to monitor their interaction with our platform.</p>
            <br/>
            <p><strong>Granting Consent:</strong> Parents or guardians can grant consent by acknowledging and accepting our Terms of Service and Privacy Policy during the account registration process. This includes agreeing to supervise the use of Kids Learn Coding LLC’s services by their children and to guide their interaction with the platform.</p>
            <br/>
            <p><strong>Supervision and Responsibility</strong></p>
            <br/>
            <p><strong>Active Parental Involvement:</strong> We strongly encourage parents and guardians to take an active role in their children’s online learning journey. This includes overseeing the courses selected, monitoring time spent on the platform, and guiding interactions within any community features or live sessions offered by Kids Learn Coding LLC.</p>
            <br/>
            <p><strong>Ensuring a Safe Learning Environment:</strong> Kids Learn Coding LLC is committed to providing a safe and positive learning environment. Parents and guardians play a crucial role in this effort by ensuring that their children understand how to safely navigate online spaces and by discussing the principles of digital citizenship.</p>
            <br/>
            <p><strong>Commitment to Privacy and Safety</strong></p>
            <br/>
            <p><strong>Adherence to Privacy Laws:</strong> While direct age verification is not conducted, Kids Learn Coding LLC is dedicated to protecting the privacy of all our users, particularly children under the age of 13. Our Privacy Policy details the measures we take to safeguard personal information and comply with COPPA and other applicable privacy laws.</p>

            <h1 id="contactInformation" >
            24. Contact Information
            </h1>
            <p>
            At Kids Learn Coding LLC, accessible via kidslearncoding.org, we are dedicated to providing an exceptional learning experience and maintaining open lines of communication with our community. Whether you have questions, feedback, or require support, we are here to assist you. Below you will find the necessary contact information to reach various departments within our organization.
            </p>
            <br/>
            <p>
              <strong>General Inquiries</strong>
            </p>
            <br/>
            <p>
            For general questions about our courses, services, or any other inquiries please see our "Contact Us" page by clicking the following link: <span className='link' onClick={()=>navigate("/contact")}>Contact Us</span>
            </p>
        </div>

        <Footer />
    </div>
  )
}

export default Terms