import React, { useState, useEffect } from 'react';
import "./success.css";
import { ToastContainer } from 'react-toastify';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

const Success = () => {

  return (
    <div className='success-page'>
        <ToastContainer />
        <Navbar />

        <div className='success-container'>    
            <div className='animation__container'>
                <div class="wrapper"> 
                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> 
                        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> 
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </div>

                <h1>
                    Registration Success,<br/>please <span className='highlights'>check your email</span> for confirmation.
                </h1>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default Success