import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import './pay.css';
import api from '../../../api/api';
import Loader from '../../../components/loader/loader';

const Pay = ({ clientSecret, formData, loading, setLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  var [isCardComplete, setIsCardComplete] = useState(false);
  var [paymentLoading, setPaymentLoading] = useState(false);


  const handleSubmit = async () => {
    setPaymentLoading(true)
    if (!stripe || !elements) {
      return;
    }
    
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });
  
    if (error || (paymentIntent && paymentIntent.status !== "succeeded" && paymentIntent.status !== "requires_capture")) {
      toast.error("Payment was not successful.");
      return;
    }
  
    try {
      // Attempt to complete registration
      await api.post(`${process.env.REACT_APP_API_URL}/register`, {
        formData,
        paymentIntentId: paymentIntent.id, // Send only the paymentIntent ID for server verification
      });
    } catch (registrationError) {
      setPaymentLoading(false)
      window.location.href = `${process.env.REACT_APP_CLIENT}/register/warning`;
    } finally {
      // Redirect to the success page after payment confirmation, regardless of registration success
      setPaymentLoading(false)
      window.location.href = `${process.env.REACT_APP_CLIENT}/register/success`;
    }
  };
  


  return (
    <>
    <div style={ paymentLoading === true ? { display: "none" } : { display: "flex" }} className='pay'>
        {clientSecret && (
          <PaymentElement 
            className='paymentElement' 
            onChange={(event) => setIsCardComplete(event.complete)}
          />
        )}

        <button className='stripeSubmitButton' onClick={()=>handleSubmit()} disabled={!stripe || !clientSecret}>Total $250/mo</button>
    </div>
    <div style={ paymentLoading === true ? { display: "flex" } : { display: "none" }} className='pay-load'>
        <Loader />
    </div>
    </>
  )
}

export default Pay